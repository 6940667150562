<template>
	<div>
		<!-- Header -->
		<b-container class="h-100vh d-flex align-items-center justify-content-center">
			<div>
				<b-row class="justify-content-center">
					<img
						src="../../../../client/src/assets/Logo/ComodeIcon.svg"
						class="logo"
						style="cursor: pointer"
						@click="$router.push('/adminApp/login')"
					/>
				</b-row>
				<div class="d-flex justify-content-center screen-title">仮パスワード発行</div>
				<b-row class="justify-content-center mx-0">
					<card>
						<b-card-header>
							<div class="text-left">
								<div class="note">仮パスワードを発行します。</div>
								<div class="note">ご登録のログインメールアドレスを入力の上、</div>
								<div class="note">仮パスワード発行ボタンを押してください。</div>
							</div>
						</b-card-header>
						<b-card-body class="px-lg-5 py-lg-5">
							<validation-observer v-slot="{ handleSubmit }" ref="formValidator">
								<div class="card-title">ログインメールアドレス</div>
								<b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
									<base-input
										alternative
										name="ログインメール"
										type="email"
										placeholder="ログインメール"
										:rules="{ required: true }"
										v-model="email"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
									<div class="d-flex align-items-center flex-column mt-3">
										<button
											native-type="submit"
											class="customButton customButton__submit"
										>
											送信
										</button>
										<div v-show="errorMessage.length" class="error-label">
											{{ errorMessage }}
										</div>
										<router-link to="/adminApp/login" class="text-info"
											><div class="direct-link-underline text-center mt-4">
												ログイン画面へ
											</div></router-link
										>
									</div>
								</b-form>
							</validation-observer>
						</b-card-body>
					</card>
				</b-row>
			</div>
		</b-container>
	</div>
</template>
<script>
import UserService from '../../services/user.service.js';
import { mapState } from 'vuex';
// const alertTimeout = 5000;
export default {
	data() {
		return {
			email: '',
			errorMessage: '',
			typeMessage: '',
		};
	},
	computed: {
		// Get projectId from store
		...mapState(['isLogin', 'userInfo']),
	},
	methods: {
		async onSubmit() {
			// this will be called only after form is valid. You can do api call here to login
			let [resetPasswordResult, error] = await await UserService.resetPassword(
				this.email
			);
			console.log([resetPasswordResult, error]);
			if (error) {
				this._showAlert(error.message, 'error');
			} else {
				this._showAlert(resetPasswordResult.message, 'success');
			}
		},
		_showAlert(message, type) {
			this.typeMessage = type;
			this.errorMessage = message;
			this.showAlert = true;
			// setTimeout(() => {
			// 	this.errorMessage = '';
			// }, alertTimeout);
		},
	},
};
</script>
<style scoped>
.error-label {
	font-size: 9.5px !important;
	white-space: pre-line;
	font-family: NotoSansJPRegular;
	color: red;
	margin: 10px 0;
}
</style>
